<template>
  <div class="page">
    <van-nav-bar title="收益提现" left-arrow @click-left="back()" />
    <div class="top">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-money"></use>
      </svg>
      <div class="text">可提现金额</div>
      <div class="amount">￥{{ wallet.coinBalance }}元</div>
      <div class="tips color-s1">提交申请后2个工作日内完成审核</div>
      <div class="button">
        <van-button
          size="mini"
          :color="COLOR_M"
          icon="plus"
          class="btn"
          @click="createExtract()"
          >立即提现</van-button
        >
      </div>
    </div>
    <div class="record">
      <van-tabs
        :active="active"
        :color="COLOR_M"
        border="true"
        @change="activeChange"
      >
        <van-tab title="待审核" name="apply"
          ><van-list
            :finished="applyFinishedShow"
            finished-text="没有更多了"
            @load="loadApplyTaskList"
          >
            <template #default>
              <van-row class="item" v-for="apply in applyList" :key="apply.id">
                <van-col span="4">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-income"></use>
                  </svg>
                </van-col>
                <van-col span="14"
                  ><div class="desc">提现金额:{{ apply.amount }}元</div>
                  <div class="time">{{ apply.time }}</div>
                </van-col>
                <van-col span="6">
                  <div class="button">
                    <van-button
                      icon="delete-o"
                      :color="COLOR_S2"
                      size="mini"
                      @click="deleteExtract(apply.id)"
                      >删除</van-button
                    >
                  </div>
                </van-col>
              </van-row>
              <van-row v-if="applyList.length <= 0">
                <van-col span="24" class="no-record">
                  <van-image
                    width="103"
                    height="103"
                    :src="
                      require('../../../../assets/images/home/no-record.png')
                    "
                  ></van-image>
                </van-col>
              </van-row> </template
          ></van-list>
        </van-tab>
        <van-tab title="历史记录" name="history"
          ><van-list
            :finished="historyFinishedShow"
            finished-text="没有更多了"
            @load="loadHistoryTaskList"
          >
            <template #default>
              <van-row
                class="item"
                v-for="history in historyList"
                :key="history.id"
              >
                <van-col span="4">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-income"></use>
                  </svg>
                </van-col>
                <van-col span="14"
                  ><div class="desc">提现金额:{{ history.amount }}元</div>
                  <div class="time">{{ history.time }}</div>
                </van-col>
                <van-col span="6">
                  <span class="state color-m" v-if="history.result === 'SUC'"
                    >成功</span
                  ><span class="state color-s1" v-if="history.result === 'FAL'"
                    >失败</span
                  >
                </van-col>
              </van-row>
              <van-row v-if="historyList.length <= 0">
                <van-col span="24" class="no-record">
                  <van-image
                    width="103"
                    height="103"
                    :src="
                      require('../../../../assets/images/home/no-record.png')
                    "
                  ></van-image>
                </van-col>
              </van-row> </template></van-list
        ></van-tab>
      </van-tabs>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import { Tabs, Tab, List, Image } from 'vant'
import '../../../../assets/icon/font-icon.js'
export default {
  components: {
    Loading: Loading,
    Share: Share,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Image.name]: Image
  },
  data () {
    return {
      loadingShow: false,
      applyFinishedShow: false,
      historyFinishedShow: false,
      active: 'apply',
      wallet: {},
      applyList: [],
      historyList: [],
      applyPage: { current: 0, size: 10, last: 0 },
      historyPage: { current: 0, size: 10, last: 0 }
    }
  },
  mounted () {
    this.retrieveWallet()
    this.$refs.share.default('main')
  },
  methods: {
    activeChange (val) {
      if (val === 'apply') {
        this.applyList = []
        this.applyPage.current = 0
        this.applyPage.last = 0
        this.retrieveApplyList()
      } else if (val === 'history') {
        this.historyList = []
        this.historyPage.current = 0
        this.historyPage.last = 0
        this.retrieveHistoryList()
      }
    },
    async retrieveWallet () {
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWallet')
      if (res.status === '200') {
        this.wallet = res.data
      }
    },
    loadApplyTaskList () {
      if (this.applyPage.last >= this.applyPage.current) {
        this.retrieveApplyList()
      } else {
        this.applyFinishedShow = true
      }
    },
    loadHistoryTaskList () {
      if (this.historyPage.last >= this.historyPage.current) {
        this.retrieveHistoryList()
      } else {
        this.historyFinishedShow = true
      }
    },
    async retrieveApplyList () {
      var pd = { current: this.applyPage.current, size: this.applyPage.size }
      this.applyPage.current = this.applyPage.current + 1
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWExtractApplyList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.applyList = this.applyList.concat(res.data)
        this.applyPage.last = res.page.last
      }
    },
    async retrieveHistoryList () {
      var pd = { current: this.applyPage.current, size: this.applyPage.size }
      this.historyPage.current = this.historyPage.current + 1
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWExtractHistoryList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.historyList = this.historyList.concat(res.data)
        this.historyPage.last = res.page.last
      }
    },
    createExtract () {
      this.$router.push({ path: '/mde/wallet/cusExtractCreate', query: { merchantCode: this.merchantCode } })
    },
    deleteExtract (id) {
      var that = this
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认要删除提现申请吗？'
      })
        .then(() => {
          // on confirm
          that.deleteExecute(id)
        })
        .catch(() => {
          // on cancel
        })
    },
    async deleteExecute (id) {
      var pd = { id: id }
      var that = this
      this.loadingShow = true
      const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/deleteWalletExtract', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示',
          message: '删除成功'
        }).then(() => {
          // on close
          that.applyList = []
          that.applyPage.current = 0
          that.applyPage.last = 0
          that.retrieveApplyList()
          this.retrieveWallet()
        })
      }
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.top {
  padding-top: 20px;
  .icon {
    width: 50px;
    height: 50px;
  }
  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  .amount {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
  }
  .tips {
    margin-top: 10px;
    font-size: 12px;
  }
  .button {
    margin-top: 15px;
    .btn {
      width: 30%;
    }
  }
}
.record {
  padding-top: 10px;
  .item {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    .icon {
      width: 35px;
      height: 35px;
    }
    .desc {
      text-align: left;
      font-weight: 600;
      padding-left: 10px;
      height: 20px;
      line-height: 20px;
    }
    .time {
      text-align: left;
      font-size: 12px;
      color: #bababa;
      padding-left: 10px;
      height: 20px;
      line-height: 20px;
    }
    .button {
      height: 30px;
      line-height: 30px;
    }
    .state {
      font-size: 15px;
      font-weight: 600;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
